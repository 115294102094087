<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #FFFFFF;overflow: hidden; height: 100vh;" width="200px">
				<div style="padding: 30px 20px;">
						<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
							<i class="iconfont ft14" :class="item.icon"></i>
							<span class="ft14 ml10">{{item.name}}</span>
							<div class="tag-num ml10" v-if="index==5 && order_num>0">{{order_num}}</div>
						</div>					
				</div>
			</a-layout-sider>
			<a-layout-content style="padding: 30px 20px;height: 100vh; background: #F7F8FA;">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		
	</div>
</template>

<script>
	export default {
		
		data() {
			return {
				showForm:false,
				order_num:0,
				menus: [
					// {
					// 	name: '商品分类',
					// 	icon: 'iconmall_cate',
					// 	key: 'mall_type',
					// 	link: '/mall'
					// },
					{
						name: '商品管理',
						icon: 'iconmall_goods',
						key: 'mall_manage_goods',
						link: '/mall'
						// link: '/mall/manageGoods'
					},
					// {
					// 	name: '限时秒杀',
					// 	icon: 'iconmall_flash_sale',
					// 	key: 'mall_flash_sale',
					// 	link: '/mall/flashSale'
					// },
					// {
					// 	name: '多人拼团',
					// 	icon: 'iconmall_group_purchase',
					// 	key: 'mall_pin_tuan',
					// 	link: '/mall/pinTuan'
					// },
					// {
					// 	name: '囤货管理',
					// 	icon: 'iconmall_tunhuo',
					// 	key: 'mall_store',
					// 	link: '/mall/store'
					// },
					{
						name: '订单管理',
						icon: 'iconmall_order',
						key: 'mall_goods_order',
						link: '/mall/order'
					},
					// {
					// 	name: '运费模板',
					// 	icon: 'iconmall_freight',
					// 	key: 'mall_freight_template',
					// 	link: '/mall/freight/template'
					// },
					// {
					// 	name: '代客下单',
					// 	icon: 'iconmall_daike',
					// 	key: 'mall_customerToOrder',
					// 	link: '/mall/customerToOrder'
					// },
					// {
					// 	name: '数据统计',
					// 	icon: 'iconappoint_tab09',
					// 	key: 'mall_statistics',
					// 	link: '/mall/statistics'
					// },
				],
			}
		},
		created(){
			this.loaddata();
		},
		methods: {
			loaddata(){
				this.$http.api('admin/getGoodsOrderNum').then(res=>{
					this.order_num=res.order_num;
				}).catch(res=>{
					console.log(res);
				})
			},
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
		}
	}
</script>

<style>
	.tag-num{
		background: #FF6600;
		border-radius: 8px;
		padding: 0 5px;
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
		height: 16px;
		line-height: 16px;
	}
</style>
